import findSelectedProduct from "./findSelectedProduct";
export default function CreatePriceArray(state: any, searchParams: any) {
    const prices: any = [];
    const { productDetails, products } = state
    const itemQuery = searchParams.get('display');
    // @ts-ignore
    Object.entries(products || {}).forEach(([productId, productPrices]) => {
        const productType = itemQuery ? findSelectedProduct(productDetails, itemQuery) : null;
        if (!itemQuery || (productType && productType.id === productId)) {
            // @ts-ignore
            Object.entries(productPrices).forEach(([priceId, priceDetails]) => {
                // @ts-ignore
                const priceWithProductId = { ...priceDetails, productId, productType, priceId };
                prices.push(priceWithProductId);
            });
        }
    });
    return prices;
}