import {Alert, Snackbar} from "@mui/material";
import {useContext, useEffect, useState} from "react";
import {SnackbarHelperContext} from "../contexts/SnackbarHelperContext";

export default function SnackbarMessage() {
    const {snackbarSettings, setSnackbarSettings} = useContext(SnackbarHelperContext);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (snackbarSettings?.message && snackbarSettings?.message.length) {
            setOpen(true);
            return;
        }
        setOpen(false);
    }, [snackbarSettings]);

    function handleClose() {
        setSnackbarSettings(null);
        setOpen(false);
    }

    console.log('AUTO HIDE TIME', snackbarSettings?.autoHideDuration)

    return (
        <Snackbar open={open} autoHideDuration={snackbarSettings?.autoHideDuration} onClose={() => setSnackbarSettings(null)}>
            <Alert onClose={handleClose} severity={snackbarSettings?.severity} sx={{ width: '100%' }}>
                {snackbarSettings?.message}
            </Alert>
        </Snackbar>
    )
}