import React, {useContext, useEffect} from "react"; // useState
import {Box, Typography, useMediaQuery, useTheme} from "@mui/material";
import {collection} from "firebase/firestore";
import {useCollectionData} from "react-firebase-hooks/firestore";
// import {useHttpsCallable} from "react-firebase-hooks/functions";
import {SnackbarHelperContext} from "../../../../common/contexts/SnackbarHelperContext";

import MarketPlaceItem from "../MartketPlaceItem";
import ProductFooter from "../../../pages/app/marketplace/ProductFooter";
import MarketPlaceItemPair from "../MarketPlaceItemPair";
import Dialog from "../../../../common/dialogs/Dialog";
import NoCardDialog from "../../../../common/dialogs/NoCardDialog";
import ProductDescription from "./ProductDescription";

import {firebase_firestore} from "../../../../config/firebaseConfig"; //firebase_functions
import useUserDetails from "../../../../common/hooks/useUserDetails";
import {useMarket} from "../../../../common/contexts/MarketContext";

import {isCurrentProduct} from "../../../pages/app/marketplace/helpers/isCurrentProduct";
import {useDialog} from "../../../../common/contexts/DialogContext";

//utils Hooks
import useDetermineIfUpgradeOrDowngrade from "./utils/useDetermineIfUpgradeOrDowngrade";
import useGetItemPrice from "./utils/useGetItemPrice";
import useAddItemToCart from "./utils/useAddItemToCart";
import useDetermineButtonText from "./utils/useDetermineButtonText";
import {useSearchParams} from "react-router-dom";
import findSelectedProduct from "../../../pages/app/marketplace/helpers/findSelectedProduct";


export default function ProductsDisplay( props: {prices: [], pricePairs: {monthly: any, annual?: any}[] }) {
    const {user} = useUserDetails();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const {setSnackbarSettings} = useContext(SnackbarHelperContext);
    const {
        state: { productDetails, annualSelected },
        dispatch,
    } = useMarket();
    const [subscriptionsObject] = useCollectionData(collection(firebase_firestore, `usermeta/${user?.uid}/subscriptions`));
    // const [hasCreditCard, setHasCreditCard] = useState<boolean>(false);

    const {
        setOpenDialog,
        setSelectedPriceId,
        setEndTrialContent
    } = useDialog();
    const setAnnualSelected = (value: boolean) => dispatch({ type: 'SET_ANNUAL_SELECTED', payload: value });

    const [searchParams] = useSearchParams();

    // const [checkUserHasCreditCard] = useHttpsCallable(
    //     firebase_functions,
    //     'checkUserHasCard'
    // );

    // TODO: HAS CREDIT CARD ISN'T USED ANYWHERE???
    // useEffect(() => {
    //     checkUserHasCreditCard().then((res: any) => {
    //         // Only update state if value has actually changed to prevent unnecessary re-renders
    //         if (res && hasCreditCard !== res.data) {
    //             setHasCreditCard(res.data);
    //         }
    //     });
    // }, [checkUserHasCreditCard, hasCreditCard]);

    useEffect(() => {
        if (!props.prices || !props.pricePairs) {
            setSnackbarSettings({severity: 'error', message: 'Item not found!'});
        }
    }, [props.prices, props.pricePairs, subscriptionsObject, setSnackbarSettings]);

    const addItemToCart = useAddItemToCart()
    const determineIfUpgradeOrDowngrade = useDetermineIfUpgradeOrDowngrade()
    const determineButtonText = useDetermineButtonText()
    const getItemPrice = useGetItemPrice();

    function openEndTrialDialog(price: (() => string ) | string, priceId: string) {
        setEndTrialContent(`This will end your free trial, charge you ${price} immediately, and unlock the ability to purchase add-ons and refill your credits and alerts.`);
        setSelectedPriceId(priceId);
        setOpenDialog('endTrial');
    }

    if (props.prices && props.pricePairs.length && subscriptionsObject) {
        const footer: any = () => {
            const currentlySelectedProduct: string = searchParams.get('display') || '';
            const selectedProduct = findSelectedProduct(productDetails, currentlySelectedProduct);

            const product = productDetails.data.find((product: any) => {
                return selectedProduct && product.id === selectedProduct.id;
            });

                return (
                    <Box p={1}>
                        <Typography px={2}>
                            <ProductFooter product={product}/>
                        </Typography>
                    </Box>
                )
        }

        return (
            <Box sx={{width: '100%'}}>
                <Box
                    sx={{
                        background: 'white',
                        mx: 'auto',
                        paddingLeft: '1rem',
                        paddingRight: '1rem',
                        paddingBottom: '0.5rem',
                        paddingTop: '0.1rem',
                        marginLeft: isMobile ? '0rem' : '2rem',
                        marginRight: isMobile ? '0rem' : '2rem',
                        marginBottom: '1rem',
                        borderRadius: '2px',
                        border: 1,
                        borderColor: 'divider',
                        width: isMobile ? '94%' : '92%',
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                <ProductDescription/>
                </Box>
                <Dialog/>
                <NoCardDialog/>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        mx: 'auto',
                    }}
                    key={Math.random()}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            justifyContent: {xs: "center", md: "left"},
                            gap: {xs: 2, xl: 2},
                        }}
                        mx={{xs: 4, xl: 4}}
                    >
                        {props.pricePairs
                            .sort((a: any, b: any) => annualSelected && a.annual && b.annual ? a.annual.price - b.annual.price > 0 ? 1 : -1 : a.monthly.price - b.monthly.price > 0 ? 1 : -1)
                            .map(pricePair => {
                                const upgradeOrDowngradeAnnual = pricePair.annual ? determineIfUpgradeOrDowngrade(pricePair.annual.priceId) : -2;
                                const upgradeOrDowngradeMonthly = determineIfUpgradeOrDowngrade(pricePair.monthly.priceId);
                                const shouldDisableButtonAnnual = upgradeOrDowngradeAnnual === -2;
                                const shouldDisableButtonMonthly = upgradeOrDowngradeMonthly === -2;
                                const monthlyButtonText = determineButtonText(pricePair.monthly);
                                const annualButtonText = pricePair.annual ? determineButtonText(pricePair.annual) : '';
                                // const isTrialing = !!pricePair.monthly.freeTrialDuration;
                                // TODO this needs to be adjusted more generally: determineSubscriptionStatus() => {trial}?
                                const price = getItemPrice(pricePair.monthly);

                                const buttonAction = isCurrentProduct(monthlyButtonText) // isTrialing && NOTE: Taking this off, if there is already a check, why does this need to be here?
                                       ? () => {
                                            const noAction = !pricePair.monthly.freeTrialDuration && !pricePair.monthly.metadata.trial;

                                            if (noAction) { // NOTE: This is the check I am referring to above
                                                return;
                                            }

                                            addItemToCart(pricePair.monthly);
                                            openEndTrialDialog( price || 'There was an issue, if this persists please contact support.', pricePair.monthly.priceId);
                                       }
                                       : () => {
                                        addItemToCart(pricePair.monthly);
                                       }

                                const buttonActionAnnual = () => {
                                    if (monthlyButtonText !== 'current plan') {
                                        addItemToCart(pricePair.annual);
                                    }
                                }

                                const monthlyMarketplaceItem = (<MarketPlaceItem
                                    product={pricePair.monthly}
                                    buttonText={monthlyButtonText}
                                    disableButton={shouldDisableButtonMonthly}
                                    setAnnual={setAnnualSelected}
                                    isAnnual={annualSelected}
                                    userSubscription={subscriptionsObject}
                                    buttonClick={buttonAction}
                                />);

                                const annualMarketplaceItem = pricePair.annual ? (<MarketPlaceItem
                                    product={pricePair.annual}
                                    buttonText={annualButtonText}
                                    disableButton={shouldDisableButtonAnnual}
                                    setAnnual={setAnnualSelected}
                                    isAnnual={annualSelected}
                                    userSubscription={subscriptionsObject}
                                    buttonClick={buttonActionAnnual}
                               />) : monthlyMarketplaceItem;

                                return <MarketPlaceItemPair
                                    key={Math.random()}
                                    showAnnual={annualSelected}
                                    annual={pricePair.annual ? annualMarketplaceItem : monthlyMarketplaceItem}
                                    monthly={monthlyMarketplaceItem}
                                />
                            })}
                    </Box>
                </Box>
                {footer()}
            </Box>
        )
    }
    return null
}