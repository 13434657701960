import {Button, Link} from "@mui/material";
import useBuildPriceIds from "./useBuildPriceIds";
import useDirectToCreditCardPage from "./useDirectToCreditCardPage";
import {useMarket} from "../../../../../common/contexts/MarketContext";
import {useSearchParams} from "react-router-dom";
import createPriceArray from "../../../../pages/app/marketplace/helpers/createPriceArray";

export default function useDetermineUpgradeText() {
    let priceIds = useBuildPriceIds;
    const {state, billingDate } = useMarket();
    const [searchParams] = useSearchParams();
    const prices = createPriceArray(state, searchParams);
    let directToCreditCard = useDirectToCreditCardPage();
    let freeTrial: null = null;

    return (price: undefined | string) => {
        if (priceIds.length > 0) {
            // @ts-ignore
            freeTrial = prices.find((price: any) => price.priceId === priceIds[0].priceId)?.freeTrialDuration
        }
        // TODO: could this use another case, annual to annual?
        if (freeTrial) {   // use the item in the cart to determine if there is a promo
            return (<>You have a {freeTrial} on your account. After your trial period ends you will be charged {price}.
                Go {}{<Link
                    onClick={() => directToCreditCard}>here</Link>} to view/update your credit card on file</>);
        } else {
            return (<>Adding this will charge the current card on file {price}, which has been prorated to the billing
                date of {billingDate}.
                To view or change your card on file click {<Button
                    onClick={() => directToCreditCard}>here</Button>}</>);
        }
    }
}
