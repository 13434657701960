import React, { useState, useEffect } from 'react';
import { Box, Dialog, DialogContent, DialogTitle, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useAuthState } from "react-firebase-hooks/auth";
import ModalContent from "../ModalContent";
import Header from "../../../../common/components/Header";
import {firebase_auth} from "../../../../config/firebaseConfig";
import {useNavigate} from "react-router-dom";
import useTitle from "../../../../common/hooks/useTitle";
import Footer from "../../../../common/components/Footer";

const iframeStyle: React.CSSProperties = {
    width: '100%',
    height: '100%',
    border: 'none',
};

type pagesOptions = 'signUp' | 'login' | 'emailVerification' | 'forgotPassword';

export default function TraderAI() {
    useTitle('DayTradeAI');
    const [user, loading] = useAuthState(firebase_auth);
    const hasDialogShown = localStorage.getItem('hasDialogShown') === 'true';
    const [showDialog, setShowDialog] = useState(hasDialogShown && !user && !loading);
    const [blurIframe, setBlurIframe] = useState(hasDialogShown && !user && !loading);
    const [state, setState] = useState<pagesOptions | undefined>(undefined);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const navigate = useNavigate();

    // const directToAiPage = useDirectToAiPage();
    // const directToAccountPage = useDirectToAccountPage();
    //
    // useEffect(() => {
    //     // redirect back to account page
    //     void directToAccountPage();
    //
    //     // open the ai page
    //     void directToAiPage();
    //
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [])

    useEffect(() => {
        if (user && !loading) {
            setBlurIframe(false);
            return setShowDialog(false);
        }

        setShowDialog(hasDialogShown && !user && !loading);
        setBlurIframe(hasDialogShown && !user && !loading);

        if (!hasDialogShown) {
            const timer = setTimeout(() => {
                localStorage.setItem('hasDialogShown', 'true');
                setShowDialog(true);
                setBlurIframe(true);
            }, 150_000); // 2 minutes and 30 seconds

            return () => clearTimeout(timer);
        }
    }, [hasDialogShown, user, loading]);


    return (
    <>
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <Header callback={(page: string) => {
                setShowDialog(true);
                setState(page as pagesOptions)
            }}/>
            <Box sx={{ flexGrow: 1, position: 'relative', overflow: 'hidden' }}>
                <iframe
                    title="TraderAI"
                    src="https://tradergpt.greenchart.com/"
                    style={{ ...iframeStyle, filter: blurIframe ? 'blur(5px)' : 'none', position: 'absolute', top: 0, left: 0, bottom: 0 }}
                />
            </Box>
            <Box sx={{ height: '40px', backgroundColor: '#141414', display: 'flex', alignItems: 'center' }}>
                <Typography onClick={() => navigate('/ai-agreement')} pr={2} sx={{ fontSize: '14px',  textAlign: 'right', color: 'gray', flexGrow: 1, cursor: 'pointer' }}>
                    General Public License
                </Typography>
            </Box>
            <Dialog
                open={showDialog}
                onClose={() => setShowDialog(true)}
                disableEscapeKeyDown // Disable closing the dialog using the Escape key
                fullScreen={isMobile}
            >
                <DialogTitle sx={{ alignSelf: "center" }}>
                    <Typography component="div" sx={{ fontSize: '24px', fontWeight: 700 }}>
                        Continue your{" "}
                        <Box component="span" color="#18BB0E" fontStyle="italic">
                            FREE
                        </Box>{" "}
                        access
                    </Typography>
                </DialogTitle>
                <DialogContent
                    sx={{
                        padding: 0,
                        width: isMobile ? "auto" : "600px",
                    }}
                >
                    <ModalContent closeModal={() => setShowDialog(false)} state={state}/>
                </DialogContent>
            </Dialog>
        </Box>
        <Footer/>
    </>
    );
}
