import { useState, useEffect } from 'react';
// Import necessary Firebase modules
import {StatusesMapper} from "../../landing/helpers/statusesMapper";
import {doc, getDoc} from "firebase/firestore";
import {firebase_firestore} from "../../config/firebaseConfig";
import useUserDetails from "./useUserDetails";
import {useHasFreeGTF} from "./useHasGTF";

const useTradingHouses = () => {
    // refers to active trading houses
    const [tradingHouses, setTradingHouses] = useState<any[]>([]);

    const {userDetails} = useUserDetails();

    const hasFreeGTF = useHasFreeGTF();

    useEffect(() => {
        if (userDetails && !tradingHouses.length) {
            const allPromises = [];

            if (!Object.keys(userDetails?.tradingHouses ?? {}).length) {
                userDetails.tradingHouses = {'greenchart': {currentStatus: {status: 'active'}}};
            }

            for (const tradingHouse of Object.keys(userDetails?.tradingHouses).sort((a, b) => a > b ? 1 : -1)) {
                // remove trading houses that don't have access to charts
                if (tradingHouse !== 'greenchart' && !StatusesMapper.hasAccessToCharts.includes(userDetails.tradingHouses[tradingHouse].currentStatus.status)) {
                    continue;
                }

                const docRef = doc(firebase_firestore, "tradingHouses", tradingHouse);
                allPromises.push(getDoc(docRef).then(docSnap => docSnap.data()));
            }

            Promise.all(allPromises).then((allTradingHouses) => {
                setTradingHouses(allTradingHouses);
            });
        }
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userDetails, hasFreeGTF]);

    return tradingHouses;
};

export default useTradingHouses;
