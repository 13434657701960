import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { verifyPasswordResetCode, confirmPasswordReset } from 'firebase/auth';
import {TextField, Button, Typography, Box} from '@mui/material';
import { firebase_auth } from '../../../config/firebaseConfig';
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

interface Props {
    continueUrl?: string;
}

const SetPasswordForm: React.FC<Props> = ({ continueUrl }) => {
    const [email, setEmail] = useState<string>('');
    const [newPassword, setNewPassword] = useState<string>('');
    const [success, setSuccess] = useState<boolean>(false);

    const navigate = useNavigate();

    function getParameterByName(name: string) {
        const urlSearchParams = new URLSearchParams(window.location.search);
        return urlSearchParams.get(name);
    }

    useEffect(() => {
        const actionCode = getParameterByName('oobCode');
        verifyPasswordResetCode(firebase_auth, actionCode || '')
            .then((accountEmail) => {
                setEmail(accountEmail);
            })
            .catch(() => {
                navigate('/');
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleResetPassword = () => {
        const actionCode = getParameterByName('oobCode');

        confirmPasswordReset(firebase_auth, actionCode || '', newPassword)
            .then(() => {
                setSuccess(true);
            })
            .catch(() => {
                navigate('/');
            });
    };

    function successMessage() {
        return (
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
            }}>
                <Typography sx={{fontSize: '24px', fontWeight: 500}}>
                    Password Updated
                </Typography>
                <Typography sx={{fontSize: '18px', color: 'gray'}}>
                    You can now login with your new password
                </Typography>
                <Box pt={2} sx={{display: 'flex', justifyContent: 'center'}}>
                    <CheckCircleOutlineIcon sx={{color: 'green', fontSize: '80px'}}/>
                </Box>
            </Box>
        );
    }

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center',  marginBottom: '48px' }}>
                {success ? (
                    successMessage()
                ) : (
                    <Box>
                        <Typography sx={{ alignSelf: 'center', fontSize: '22px', fontWeight: 500 }}>
                            Set New Password
                        </Typography>
                        <Typography variant="subtitle1">Email: {email}</Typography>
                        <TextField
                            fullWidth
                            label="New Password"
                            type="password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            margin="normal"
                        />
                        <Button fullWidth variant="contained" color="primary" onClick={handleResetPassword}>
                            Set Password
                        </Button>
                        {continueUrl && (
                            <Button fullWidth variant="outlined" color="primary" href={continueUrl}>
                                Continue
                            </Button>
                        )}
                    </Box>
                )}
        </Box>
    );
};

export default SetPasswordForm;
