import React, {useEffect, useState} from 'react';
import {Box, Grid, TextField, Typography} from "@mui/material";
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import {styled} from "@mui/material/styles";
import useGetUsermeta from "../hooks/useGetUsermeta";

export interface userDetailsFormValues {
    firstName: string,
    lastName: string,
    phone: string,
    phoneValidated: boolean,
}

const titleStyle = { fontFamily: 'var(--stripeFontFamily)', marginBottom: '4px', color: 'rgb(48, 49, 61)', fontSize: '14.88px', display: 'inline' };
const errorTitleStyle = {...titleStyle, marginTop: '4px', color: '#df1b41'};

const StyledTextField = styled(TextField)(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
        height: '46.39px !important',
        '& fieldset': {
            border: '1px solid #e6e6e6',
            transition: 'background 0.15s ease, border 0.15s ease, box-shadow 0.15s ease, color 0.15s ease',
            boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 1px 6px rgba(0, 0, 0, 0.02)',
        },
        '&:hover fieldset': {
            borderColor: '#e6e6e6',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'var(--p-colorPrimaryHover)',
            boxShadow: '0 0 0 2px var(--p-colorPrimaryAlpha20), 0 0 0 .1px var(--p-colorPrimaryActive)',
        },
        '&.Mui-error fieldset': {
            borderColor: '#df1b41',
            borderWidth: '2px !important',
            boxShadow: 'none',
        },
        '&.Mui-error.Mui-focused fieldset': {
            borderColor: 'var(--p-colorPrimaryHover)',
            borderWidth: '1px !important',
            boxShadow: '0 0 0 2px var(--p-colorPrimaryAlpha20), 0 0 0 .1px var(--p-colorPrimaryActive)',
        },
    },
}));

export default function UserDetailsForm(props: {infoUpdated: (data: any) => void }) {
    // auto-populate form fields with usermeta data, these are all required for checkout
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phone, setPhone] = useState('');

    const [fnError, setfnError] = useState('');
    const [lnError, setlnError] = useState('');
    const [numberError, setNumberError] = useState('');

    const { user, usermeta } = useGetUsermeta();

    const phoneInputClass = numberError.length > 0 ? 'customPhoneInputError customPhoneInput' : 'customPhoneInput';

    // Update this handler for last name field
    const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (!value.length) {
            setlnError('Last name is required');
        } else {
            setlnError('');
        }
        setLastName(value);
    };

    // Update this handler for phone number field
    const handlePhoneNumberChange = (number: string) => {
        if (!isValidPhoneNumber(number || '')) {
            setNumberError('Invalid phone number');
        } else {
            setNumberError('');
        }
        setPhone(number);
    };

    useEffect(() => {
        if (firstName || lastName || phone) {
            const data: userDetailsFormValues = { firstName, lastName, phone, phoneValidated: isValidPhoneNumber(phone || '') };
            props.infoUpdated(data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [firstName, lastName, phone]);

    useEffect(() => {
        if (!usermeta || !user) return;

        setFirstName(usermeta?.firstName);
        setLastName(usermeta?.lastName);
        // if (usermeta?.phoneNumber) {
        //     let phoneNumber = usermeta?.phoneNumber?.phoneNumber ? usermeta?.phoneNumber.phoneNumber : '+1' + usermeta?.phoneNumber
        //     // Assume that it should be a US number if it doesn't start with a +
        //     if (phoneNumber[0] !== '+') {
        //         phoneNumber = '+1' + phoneNumber;
        //     }
        //
        //     setPhone(phoneNumber);
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [usermeta?.firstName, usermeta?.lastName, usermeta?.phoneNumber])

    if (!user || !usermeta) {
        return <div>loading...</div>
    }

    return (
        <Box py={2}>
            <>
                <Grid container>
                    <Grid item xs={6}>
                        <Box sx={{ pr: 1 }}>
                            <Typography
                                variant="caption"
                                display="block"
                                gutterBottom
                                sx={titleStyle}
                            >
                                First Name
                            </Typography>
                            <StyledTextField
                                error={fnError.length > 0}
                                InputProps={{
                                    style: { height: '44.39px' }  // Adjust padding as needed
                                }}
                                sx={{ height: '44.39px', margin: '0' }}
                                placeholder="First Name"
                                variant="outlined"
                                fullWidth
                                value={firstName}
                                onChange={(e) => {
                                    if (!e.target.value.length) {
                                        setfnError('First name is required');
                                    } else {
                                        setfnError('');
                                    }

                                    setFirstName(e.target.value)
                                }}
                            />
                            <Typography
                                mt={1}
                                variant="caption"
                                display="block"
                                gutterBottom
                                sx={errorTitleStyle}
                            >
                                {fnError}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box sx={{ pl: 1 }}>
                            <Typography
                                variant="caption"
                                display="block"
                                gutterBottom
                                sx={titleStyle}
                            >
                                Last Name
                            </Typography>
                            <StyledTextField
                                error={lnError.length > 0}
                                InputProps={{
                                    style: { height: '44.39px' }  // Adjust padding as needed
                                }}
                                sx={{ height: '44.39px', margin: '0' }}
                                placeholder="Last Name"
                                variant="outlined"
                                fullWidth
                                value={lastName}
                                onChange={handleLastNameChange}
                            />
                            <Typography
                                mt={1}
                                variant="caption"
                                display="block"
                                gutterBottom
                                sx={errorTitleStyle}
                            >
                                {lnError}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </>
            <Box pt={1.5}>
                <Typography
                    variant="caption"
                    display="block"
                    gutterBottom
                    sx={titleStyle}
                >
                    Phone Number
                </Typography>
                <PhoneInput
                    className={phoneInputClass}
                    value={phone}
                    limitMaxLength={true}
                    defaultCountry={'US'}
                    placeholder={'Phone Number (required)'}
                    onChange={handlePhoneNumberChange}
                />
                <Typography
                    mt={1}
                    variant="caption"
                    display="block"
                    gutterBottom
                    sx={errorTitleStyle}
                >
                    {numberError}
                </Typography>
            </Box>
        </Box>
    );
}