import {Navigate, useLocation, useSearchParams} from "react-router-dom";
import React, {useContext, useEffect} from "react";
import {GeneralContext} from "../contexts/GeneralContext";
import ProgressCircle from "../components/ProgressCircle";
import useUserDetails from "../hooks/useUserDetails";

// If authenticated, don't allow sign up and login flow to be displayed
export default function UnauthenticatedRouteGuard({ children }: any) {
    const [searchParams] = useSearchParams();
    const {preserveUrlParams, checkAccountStatuses, setRepId} = useContext(GeneralContext);

    const location = useLocation(); // get location from useLocation hook

    const {user, loadingUser: loading, loadingUserDetails: loadingUserMeta, userDetails: usermeta} = useUserDetails();

    useEffect(() => {
        if (user && !loading && usermeta) {
            checkAccountStatuses(usermeta);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [usermeta])

    if (loading || loadingUserMeta) {
        return <ProgressCircle/>;
    }
    if (user && !loading && usermeta) {
        if (location) {
            const params = new URLSearchParams(location.search);
            setRepId(params.get('repId'));
        }

        localStorage.setItem('isAuthenticated', 'true');

        if (children.type.name === 'TraderAI') {
            return children;
        }

        const {hasChartingAccess, hasGreenChartTradingHouse} = checkAccountStatuses(usermeta)

        if (searchParams.get('display')) {
            return <Navigate to={`/dashboard/marketplace${preserveUrlParams(searchParams)}`} replace />;
        } else if (hasGreenChartTradingHouse && !hasChartingAccess) {
            return <Navigate to={`/dashboard/marketplace?display=membership`}/>
        } else {
            return <Navigate to={`/dashboard/account${preserveUrlParams(searchParams)}`} replace />;
        }
    }

    localStorage.setItem('isAuthenticated', 'false');
    return children
};
